import { action, makeObservable, observable } from 'mobx';

export class ArchiveDocsStore {
  @observable public archiveDocs: ArchiveDoc[] = [];
  @observable public archiveDocsMeta: ResponseMeta = {
    totalPages: 0,
    totalRecords: 0,
  };

  constructor() {
    makeObservable(this);
  }

  @action
  public setArchiveDocs = (docs: ArchiveDoc[], meta: ResponseMeta) => {
    this.archiveDocs = docs;
    this.archiveDocsMeta = meta;
  };

  @action
  public setArchiveDoc = (doc: ArchiveDoc) => {
    this.archiveDocs = [doc, ...this.archiveDocs];
  };

  @action
  public updateArchiveDocs = (doc: ArchiveDoc) => {
    this.archiveDocs = this.archiveDocs.map((t) =>
      t.id === doc.id ? Object.assign(t, doc) : t,
    );
  };

  @action
  public deleteArchiveDocs = (id: number) => {
    this.archiveDocs = this.archiveDocs.filter((e) => e.id !== id);
  };
}
