import { action, makeObservable, observable } from 'mobx';

export class Price {
  public readonly id: number;
  public readonly counterpartyId?: number;
  @observable public name = '';
  @observable public description = '';
  @observable public active = false;
  @observable public base = false;
  @observable public activationDate?: string;
  @observable public services: PriceService[] = [];
  @observable public servicesIds: number[] = [];
  @observable public priceType: PriceType;
  @observable public counterparty?: Counterparty;

  constructor(price: PriceResponse) {
    this.id = price.id;
    this.counterpartyId = price.counterparty_id;
    this.name = price.name;
    this.description = price.description;
    this.activationDate = price.effective_date;
    this.active = price.active;
    this.base = price.base;
    this.servicesIds = price.services_ids;
    this.priceType = price.price_type;
    this.counterparty = price.counterparty;
    makeObservable(this);
  }

  @action
  public setValue(
    key: keyof Omit<Price, 'id' | 'counterpartyId'>,
    value: unknown,
  ): void {
    this[key] = value as never;
  }

  @action
  public setServiceId = (action: 'add' | 'remove', id: number) => {
    if (action === 'add') {
      this.servicesIds = [...this.servicesIds, id];
    }
    if (action === 'remove') {
      this.servicesIds = this.servicesIds.filter((e) => e !== id);
    }
  };
}
