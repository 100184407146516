import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCurvedArrowNextIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_31531_14937)">
        <path
          d="M18.9 10.6C17.05 8.99 14.65 8 12 8C7.34999 8 3.41999 11.03 2.03999 15.22L4.39999 16C5.44999 12.81 8.44999 10.5 12 10.5C13.95 10.5 15.73 11.22 17.12 12.38L13.5 16H22.5V7L18.9 10.6Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_31531_14937">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCurvedArrowNextIcon;
