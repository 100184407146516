import { AuthService } from '@modules/Auth';
import { ProfileService } from '@modules/Profile';
import { RoleService } from '@modules/Roles';
import { UiModulesService } from '@modules/UiModules';
import { PermissionsService } from '@modules/Permissions';
import { EntityService } from '@modules/Entity';
import { PositionService } from '@modules/Position';
import { SpecializationService } from '@modules/Specialization';
import { HealthFacilityService } from '@modules/HealthFacility';
import { CabinetsService } from '@modules/Cabinets';
import { ServicesService } from '@modules/Services';
import { ScheduleStaffService } from '@modules/ScheduleStaff';
import { PatientsService } from '@modules/Patients';
import { CalendarService } from '@modules/Calendar';
import { ErrorService } from '@modules/Error';
import { PricesService } from '@modules/Prices';
import { CurrencyService } from '@modules/Currency';
import { AppService } from '@modules/App';
import { WsService } from '@modules/WebSocket';
import { ApiService } from '@modules/Api';
import { UsersService } from '@modules/Users';
import {
  AppealApi,
  CabinetsApi,
  DocTemplateApi,
  EntityApi,
  HealthFacilityApi,
  MedCardsApi,
  OrdersApi,
  PatientsApi,
  PaymentApi,
  PermissionApi,
  PositionsApi,
  PricesApi,
  RoleApi,
  ServicesApi,
  SpecialisationApi,
  UiModulesApi,
  UsersApi,
  VisitApi,
  DocumentsApi,
  LabServicesApi,
  InsuranceApi,
  BiomaterialApi,
  ConsumableApi,
  DocDirectionsApi,
  AppointmentApi,
  ScheduleApi,
  AuthApi,
  AddressApi,
  UserTemplateApi,
  CashPlaceApi,
  PreferentialCategoriesApi,
  PhoneCodesApi,
  ServicePropertyApi,
  ReferenceValuesApi,
  DocIcdApi,
  ArchiveDocsApi,
  BiomaterialServiceApi,
  BiomaterialServicePrintTaskApi,
  DeviceApi,
  RemedDoctorApi,
  RemedPatientApi,
  PayersApi,
  CounterpartyApi,
  CounterpartyReportApi,
  ReferralDoctorApi,
  RemedEpisodeApi,
  PackageServiceApi,
  ScheduledCallApi,
  PatientPkgServiceApi,
  PackageOrderApi,
} from '@modules/Api/methods';
import { ModalService } from '@modules/Modal';
import { AdministratorService } from '@modules/Administrator';
import { EnvService } from '@modules/Env';
import { NotifyService } from '@modules/Notify';
import { RoutingService } from '@modules/Routing';
import { LoadingService } from '@modules/Loading';
import { LayoutService } from '@modules/Layout';
import { DrawerService } from '@modules/Drawer';
import * as StorageService from '@modules/Storage';
import { StringService } from '@modules/String';
import { DateService } from '@modules/Format';
import { CashPlaceService } from '@modules/CashPlace';
import { VisitService } from '@modules/Visit';
import { PaymentService } from '@modules/Payment';
import { OrderService } from '@modules/Order';
import { AppealService } from '@modules/Appeals';
import { DocumentsService } from '@modules/Documents';
import { MedCardService } from '@modules/MedicalCard';
import { LabServicesService } from '@modules/LabServices';
import { WsApi } from '@modules/WebSocket/api';
import { InsuranceService } from '@modules/Insurance';
import { InitSystemService } from '@modules/Init';
import { BiomaterialService } from '@modules/Biomaterial';
import { ConsumableService } from '@modules/Consumable';
import { DocDirectionsService } from '@modules/DocDirections';
import { LabAppointmentsService } from '@modules/LabAppointments';
import { LoggerService } from '@modules/Logger';
import { AddressService } from '@modules/Address';
import { CertificatesService } from '@modules/Certificates';
import { PreferentialCategoriesService } from '@modules/PreferentialCategories';
import { PhoneCodesService } from '@modules/PhoneCodes';
import { ServicePropertiesService } from '@modules/ServiceProperties';
import { ReferenceValuesService } from '@modules/ReferentValues';
import { ArchiveDocsService } from '@modules/ArchiveDocs';
import { DoctorWorkplaceService } from '@modules/DoctorWorkplace';
import { AppointmentsApi } from '@modules/Api/methods/Appointments';

class StoreProvider {
  public AppService = new AppService(this);
  public AuthApi = new AuthApi(this);
  public AuthService = new AuthService(this);
  public ProfileService = new ProfileService(this);
  public WsService = new WsService(this);
  public RoleService = new RoleService(this);
  public UiModulesService = new UiModulesService(this);
  public PermissionsService = new PermissionsService(this);
  public EntityService = new EntityService(this);
  public ErrorService = new ErrorService();
  public PositionService = new PositionService(this);
  public SpecializationService = new SpecializationService(this);
  public HealthFacilityService = new HealthFacilityService(this);
  public CabinetsService = new CabinetsService(this);
  public ServicesService = new ServicesService(this);
  public ScheduleStaffService = new ScheduleStaffService(this);
  public PatientsService = new PatientsService(this);
  public CalendarService = new CalendarService(this);
  public PricesService = new PricesService(this);
  public CurrencyService = new CurrencyService(this);
  public ApiService = new ApiService(this);
  public UsersService = new UsersService(this);
  public WsApi = new WsApi(this);
  public PatientsApi = new PatientsApi(this);
  public PricesApi = new PricesApi(this);
  public UsersApi = new UsersApi(this);
  public RoleApi = new RoleApi(this);
  public UiModulesApi = new UiModulesApi(this);
  public SpecialisationApi = new SpecialisationApi(this);
  public CabinetsApi = new CabinetsApi(this);
  public HealthFacilityApi = new HealthFacilityApi(this);
  public VisitApi = new VisitApi(this);
  public EntityApi = new EntityApi(this);
  public PositionsApi = new PositionsApi(this);
  public PermissionApi = new PermissionApi(this);
  public BiomaterialServiceApi = new BiomaterialServiceApi(this);
  public ServicesApi = new ServicesApi(this);
  public PaymentApi = new PaymentApi(this);
  public AppealApi = new AppealApi(this);
  public ScheduledCallApi = new ScheduledCallApi(this);
  public OrdersApi = new OrdersApi(this);
  public DocTemplateApi = new DocTemplateApi(this);
  public DocumentsApi = new DocumentsApi(this);
  public MedCardApi = new MedCardsApi(this);
  public DocDirectionsApi = new DocDirectionsApi(this);
  public AdministratorService = new AdministratorService(this);
  public DoctorWorkplace = new DoctorWorkplaceService(this);
  public CashPlaceApi = new CashPlaceApi(this);
  public CashPlaceService = new CashPlaceService(this);
  public VisitService = new VisitService(this);
  public PaymentService = new PaymentService(this);
  public OrderService = new OrderService(this);
  public AppealService = new AppealService(this);
  public DocumentsService = new DocumentsService(this);
  public MedCardService = new MedCardService(this);
  public LabServicesApi = new LabServicesApi(this);
  public ArchiveDocsApi = new ArchiveDocsApi(this);
  public AppointmentsApi = new AppointmentsApi(this);
  public LabServicesService = new LabServicesService(this);
  public InsuranceApi = new InsuranceApi(this);
  public InsuranceService = new InsuranceService(this);
  public BiomaterialApi = new BiomaterialApi(this);
  public BiomaterialService = new BiomaterialService(this);
  public ConsumableApi = new ConsumableApi(this);
  public ConsumableService = new ConsumableService(this);
  public InitSystemService = new InitSystemService(this);
  public DirectionsService = new DocDirectionsService(this);
  public LabAppointmentsService = new LabAppointmentsService(this);
  public RemedDoctorApi = new RemedDoctorApi(this);
  public RemedPatientApi = new RemedPatientApi(this);
  public RemedEpisodeApi = new RemedEpisodeApi(this);
  public AppointmentApi = new AppointmentApi(this);
  public ScheduleApi = new ScheduleApi(this);
  public AddressApi = new AddressApi(this);
  public AddressService = new AddressService(this);
  public UserTemplateApi = new UserTemplateApi(this);
  public PreferentialCategoriesApi = new PreferentialCategoriesApi(this);
  public DocIcdApi = new DocIcdApi(this);
  public CertificatesService = new CertificatesService(this);
  public PreferentialCategoriesService = new PreferentialCategoriesService(
    this,
  );
  public PhoneCodesService = new PhoneCodesService(this);
  public PhoneCodesApi = new PhoneCodesApi(this);
  public ServicePropertiesService = new ServicePropertiesService(this);
  public ServicePropertyApi = new ServicePropertyApi(this);
  public ReferenceValuesApi = new ReferenceValuesApi(this);
  public DeviceApi = new DeviceApi(this);
  public BiomaterialServicePrintTaskApi = new BiomaterialServicePrintTaskApi(
    this,
  );
  public ReferenceValuesService = new ReferenceValuesService(this);
  public ArchiveDocsService = new ArchiveDocsService(this);
  public PayersApi = new PayersApi(this);
  public CounterpartyApi = new CounterpartyApi(this);
  public CounterpartyReportApi = new CounterpartyReportApi(this);
  public ReferralDoctorApi = new ReferralDoctorApi(this);
  public PackageServiceApi = new PackageServiceApi(this);
  public PatientPkgServiceApi = new PatientPkgServiceApi(this);
  public PackageOrderApi = new PackageOrderApi(this);
  public DateService = DateService;
  public RoutingService = RoutingService;
  public LoadingService = LoadingService;
  public LayoutService = LayoutService;
  public EnvService = EnvService;
  public ModalService = ModalService;
  public DrawerService = DrawerService;
  public NotifyService = NotifyService;
  public StringService = StringService;
  public StorageService = StorageService;
  public LoggerService = LoggerService;
}

export default new StoreProvider();
