import React, { useEffect } from 'react';
import Tag from '@components/UI/Tag';
import { Text } from '@components/UI/Text';
import { Button } from '@components/UI/Button';
import { observer } from 'mobx-react';
import { useService } from '@stores/hooks';
import { useTranslation } from 'react-i18next';
import { OverlayOptions } from '@components/UI/OverlayPanel';
import { Loader } from '@components/UI/Loader';

type Props = OverlayOptions & {
  event: DoctorWorkplace.VisitEvent;
};

function VisitDocuments({ close, event }: Props): React.JSX.Element | null {
  const { t } = useTranslation('workplace');
  const { goToRoute } = useService('RoutingService');
  const { loadings } = useService('LoadingService');
  const { getDocuments } = useService('DocumentsApi');

  useEffect(() => {
    if (event.visit.active_visit) {
      getDocuments({
        'filter[visit_id]': event.visit.active_visit.id,
      })
        .then((res) => res.data)
        .then((documents) => {
          if (documents?.length) event.documents.set(documents);
        });
    }
  }, [getDocuments, event]);

  if (loadings.GET_DOCUMENTS) {
    return (
      <div className="mt-4 h-[80px] relative">
        <Loader />
      </div>
    );
  }

  if (!event.documents.list.length) {
    return (
      <div className="w-full mt-6 mb-4">
        <Text tag="h4" className="text-center" type="secondary">
          {t('layout:no_results_title')}
        </Text>
      </div>
    );
  }

  return (
    <div data-testid="active-visit-documents" className="mt-4">
      {event.documents.list.map((data) => (
        <div
          key={data.episode.id}
          className="bg-coolGray-100 py-2 px-3 mb-2 rounded-1"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Text tag="h4" className="text-coolGray-500">
                {t('active_visit.episode')}
              </Text>
              <Text
                tag="h4"
                className="text-coolGray-900 truncate max-w-[200px] ml-2"
              >
                {data.episode.title}
              </Text>
            </div>
            <Button
              icon="ArrowForward"
              size="mini"
              type="info"
              onClick={() => {
                close();
                goToRoute('/patients/table/:id/episodes/:episodeId', {
                  id: data.patient.id,
                  episodeId: data.episode.id,
                });
              }}
            />
          </div>
          <div>
            {data.documents.map((doc) => (
              <Tag
                key={doc.id}
                size="medium"
                type="white"
                className="mt-1 mr-1"
              >
                {doc.template_title}
              </Tag>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default observer(VisitDocuments);
