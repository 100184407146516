import { Options } from 'reconnecting-websocket';
import { EnvService } from '@modules/Env';

export const WS_CONNECTION_CONFIG = {
  url: EnvService.env.ws.url,
  options: { connectionTimeout: 1000, debug: false } as Options,
};

export const WS_RESPONSE_KEY_MAPPER: WsResponseKeyMapper = {
  'ws_close': null as any,
  // core
  'authentication': 'AUTH_WS_CONNECTION',
  // schedule_interval
  'schedule_interval.get_list': 'GET_SCHEDULE_STAFF',
  'schedule_interval.get_available': 'GET_AVAILABLE_EXECUTORS',
  'schedule_interval.get_list_events_only': 'GET_SCHEDULE_STAFF_EVENTS',
  'schedule_interval.create': 'CREATE_SCHEDULE_STAFF_EVENT',
  'schedule_interval.mass_create': 'CREATE_MASS_SCHEDULE_STAFF_EVENT',
  'schedule_interval.update': 'UPDATE_SCHEDULE_STAFF_EVENT',
  'schedule_interval.confirm': 'CONFIRM_SCHEDULE_STAFF_EVENT',
  'schedule_interval.unconfirm': 'UNCONFIRM_SCHEDULE_STAFF_EVENT',
  'schedule_interval.delete': 'DELETE_SCHEDULE_STAFF_EVENT',
  'schedule_interval.subscribe': 'SCHEDULE_STAFF_SUBSCRIBE',
  'schedule_interval.unsubscribe': 'SCHEDULE_STAFF_UNSUBSCRIBE',
  // Appointment
  'appointments.create': 'CREATE_APPOINTMENT',
  'appointments.get_list': 'GET_APPOINTMENTS',
  'appointments.get': 'GET_APPOINTMENT',
  'appointments.get_history': 'GET_APPOINTMENT_HISTORY',
  'appointments.update': 'UPDATE_APPOINTMENT',
  'appointments.confirm_batch': 'CONFIRM_BATCH_APPOINTMENT',
  'appointments.change_status': 'APPOINTMENT_CHANGE_STATUS',
  'appointments.delete': 'DELETE_APPOINTMENT',
  'appointments.subscribe': 'APPOINTMENTS_SUBSCRIBE',
  'appointments.unsubscribe': 'APPOINTMENTS_UNSUBSCRIBE',
  'appointments.create_secondary': 'CREATE_APPOINTMENT_SECONDARY',
  'appointments.add_or_remove_service': 'APPOINTMENTS_TOGGLE_SERVICES',
  'appointments.update_service_count': 'UPDATE_APPOINTMENT_SERVICE_COUNT',
  'appointments.replace_service': 'APPOINTMENTS_REPLACE_SERVICE',
  'appointments.reset_referring_doctor': 'APPOINTMENTS_RESET_REFERRAL',
  'appointments.outside_approve': 'APPOINTMENT_OUTSIDE_APPROVE',
  // Calendar
  'calendar.get_calendar_table': 'GET_CALENDAR',
  'calendar.create': 'GET_CALENDAR',
  'calendar.update': 'GET_CALENDAR',
  'calendar.change_status': 'GET_CALENDAR',
  'calendar.delete': 'GET_CALENDAR',
  'calendar.subscribe': 'CALENDAR_SUBSCRIBE',
  'calendar.unsubscribe': 'CALENDAR_UNSUBSCRIBE',
};
