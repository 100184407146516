import { PreloadLazy } from '@router/utils';

export const LABORATORY_ROUTES: PrivateRoute = {
  group: 'LABORATORY',
  name: 'laboratory',
  path: '/laboratory',
  exact: false,
  sidebar: true,
  icon: 'Laboratory',
  activeIcon: 'OutlineLaboratory',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'laboratory',
      path: '/laboratory',
      exact: true,
      component: PreloadLazy(() => import('@views/Laboratory')),
    },
    {
      name: 'laboratory_workplace',
      path: '/laboratory/workplace',
      exact: false,
      component: PreloadLazy(() => import('@views/Laboratory/Workplace')),
      imagePath: () => import('@assets/img/preview/blood_test.svg'),
    },
    {
      name: 'laboratory_analyzes',
      path: '/laboratory/analyzes',
      exact: false,
      component: PreloadLazy(() => import('@views/Laboratory/Analyzes')),
      imagePath: () => import('@assets/img/preview/blood_test.svg'),
    },
    {
      group: 'LABORATORY_BIOMATERIALS',
      name: 'laboratory_biomaterials',
      path: '/laboratory/biomaterials',
      exact: false,
      sidebar: true,
      icon: 'Laboratory',
      activeIcon: 'OutlineLaboratory',
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      routes: [
        {
          name: 'laboratory_biomaterials',
          path: '/laboratory/biomaterials',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Laboratory/Biomaterials'),
          ),
        },
        {
          name: 'laboratory_biomaterials_sent',
          path: '/laboratory/biomaterials/sent',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Laboratory/Biomaterials/Sent'),
          ),
          imagePath: () => import('@assets/img/preview/documents_elem.svg'),
        },
        {
          name: 'biomaterials_spoiled',
          path: '/laboratory/biomaterials/spoiled',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Laboratory/Biomaterials/Spoiled'),
          ),
          imagePath: () => import('@assets/img/preview/blood_test.svg'),
        },
      ],
    },
  ],
};
