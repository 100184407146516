import { LStorage } from '@modules/Storage';

// Функція для оновлення кількості вкладок
const updateTabCount = (action: 'open' | 'close') => {
  let currentCount = parseInt(LStorage.getItem('OPEN_TABS') || '0');
  if (action === 'open') {
    currentCount += 1;
  } else if (action === 'close') {
    currentCount = Math.max(currentCount - 1, 0);
  }
  LStorage.setItem('OPEN_TABS', String(currentCount));
  //  console.log('Number of open tabs:', currentCount);
};

// Оновлюємо кількість вкладок при відкритті нової вкладки
document.addEventListener('DOMContentLoaded', () => updateTabCount('open'));
// Оновлюємо кількість вкладок при закритті поточної вкладки
window.addEventListener('beforeunload', () => updateTabCount('close'));
