import { FetchService } from '@modules/Api';

export class AppointmentsApi extends FetchService {
  public getLabSecondaryAppointments = (
    query: GetLabSecondaryAppointmentsQuery,
  ) => {
    return this.request<'APPOINTMENTS', LabSecondaryAppointment[]>(
      '/appointments/lab_with_secondary',
      'GET_LAB_SECONDARY_APPOINTMENTS',
      {
        query,
      },
    );
  };
}
