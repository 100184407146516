import { ArchiveDocsStore } from '@modules/ArchiveDocs/archive.docs.store';

export class ArchiveDocsService extends ArchiveDocsStore {
  protected _store: Store;

  constructor(store: Store) {
    super();
    this._store = store;
  }

  public getArchiveDocsReq = async (query?: GetArchiveDocsQuery) => {
    const res = await this._store.ArchiveDocsApi.getArchiveDocs(query);
    if (res.success && res.data) {
      this.setArchiveDocs(res.data, res.meta);
    }
    return res;
  };

  public updateArchiveDocsReq = async (id: number, data: UpdateArchiveDoc) => {
    const res = await this._store.ArchiveDocsApi.updateArchiveDoc(id, data);
    if (res.success && res.data) {
      this.updateArchiveDocs(res.data);
    }
    return res;
  };

  public uploadArchiveDocReq = async (params: FormData) => {
    const res = await this._store.ArchiveDocsApi.uploadArchiveDoc(params);
    if (res.success && res.data) {
      this.setArchiveDoc(res.data);
    }
    return res;
  };

  public deleteArchiveDocReq = async (id: number) => {
    const res = await this._store.ArchiveDocsApi.deleteArchiveDoc(id);
    if (res.success) {
      this.deleteArchiveDocs(id);
    }
    return res;
  };
}
