import { reaction } from 'mobx';
import { Store } from '@stores';

const TIME_INTERVAL = 30e3; // 30 sec
const TIME_RECONNECT = 1e3; // 1 sec

class HeartbeatManager {
  private _ws = Store.WsService;
  private _pingPongId?: NodeJS.Timeout;
  private _reconnectId?: NodeJS.Timeout;

  private _isWsOpen() {
    return this._ws.connection?.readyState === WebSocket.OPEN;
  }

  private _isDocumentVisible() {
    return document.visibilityState === 'visible';
  }

  // Очищення всіх таймерів
  private _stopAll(): void {
    this.stopPing();
    this.stopReconnect();
  }

  // Очищення інтервалу для ping
  public stopPing(): void {
    if (this._pingPongId) {
      clearInterval(this._pingPongId);
      this._pingPongId = undefined;
    }
  }

  // Очищення таймауту для reconnect
  public stopReconnect(): void {
    if (this._reconnectId) {
      clearTimeout(this._reconnectId);
      this._reconnectId = undefined;
    }
  }

  public startPing = () => {
    if (!this._isDocumentVisible() || !this._isWsOpen()) return;
    this.stopPing();
    this._ws.sendAuthentication();
    this._pingPongId = setInterval(this.startPing, TIME_INTERVAL);
  };

  public reconnect = () => {
    if (!this._isDocumentVisible()) return;
    this._stopAll();
    this._reconnectId = setTimeout(() => {
      this._ws.connect({ callback: this.startPing });
    }, TIME_RECONNECT);
  };

  public setupVisibilityChange(): void {
    document.addEventListener('visibilitychange', () => {
      if (this._isDocumentVisible()) {
        if (!this._isWsOpen()) {
          this.reconnect();
        } else {
          this.startPing();
        }
      } else {
        this.stopReconnect();
      }
    });
  }

  public connectWS(): void {
    this._stopAll();
    this._ws.connect({ callback: this.startPing });
    this._ws.on('ws_close', this.reconnect);
  }

  public disconnectWS(): void {
    this._ws.disconnect();
    this._stopAll();
    this._ws.off('ws_close', this.reconnect);
  }
}

const manager = new HeartbeatManager();
manager.setupVisibilityChange();

export const profileReactions = reaction(
  () => Store.ProfileService.profile,
  (profile) => {
    if (profile) {
      manager.connectWS();
    } else {
      manager.disconnectWS();
    }
  },
);
