import { CalendarListener } from './listener';
import { CalendarStore } from './store';

export class Calendar extends CalendarListener {
  public store: CalendarStore;

  constructor(protected _store: Store) {
    super(_store);
    this.store = new CalendarStore(_store);
  }

  public getCalendar = () => {
    const { calendarStore, calendarFilter } = this._store.CalendarService;
    const { calendar } = this._store.WsApi;
    const { dateToUnix } = this._store.DateService;

    const query = { ...calendarFilter.filters };

    if (calendarStore.candidateQuery) {
      query.appointment_candidates = calendarStore.candidateQuery;
      query.from_timestamp = dateToUnix(new Date());
    }

    if (calendarStore.replanEvent) {
      query.rescheduling_event_id = calendarStore.replanEvent.id;
    }

    calendar.getCalendarTable(query);
  };

  #getAvailableIntervals = (
    params: GetAvailableIntervalsReq,
  ): Promise<WsResponse<AvailableExecutor[]>> => {
    const { once } = this._store.WsService;

    return new Promise((resolve) => {
      once('schedule_interval.get_available', resolve).send(
        {
          method: 'schedule_interval.get_available',
          params,
        },
        'GET_AVAILABLE_EXECUTORS',
      );
    });
  };

  public getIntervals = async (params: GetAvailableIntervalsReq) => {
    const { result } = await this.#getAvailableIntervals(params);
    if (!result.success) return;

    const args: Parameters<typeof this.store.updateInterval>[] =
      result.data.map((data) => {
        const dates = data.events.map((e) => e.date);
        return [data.user.id, dates, data.events];
      });

    args.map((data) => this.store.updateInterval(...data));
  };

  public unsubscribe = () => {
    const { calendar } = this._store.WsApi;
    const { isAuth } = this._store.WsService;
    if (isAuth) calendar.unsubscribeCalendar();
  };
}
