import WebSocketStore from '@modules/WebSocket/ws.store';
import {
  WS_CONNECTION_CONFIG,
  WS_RESPONSE_KEY_MAPPER,
} from '@modules/WebSocket/consts';
import uniqueId from 'lodash/uniqueId';
import * as Events from 'reconnecting-websocket/events';
import { LStorage } from '@modules/Storage';

export class WsService extends WebSocketStore {
  public connection: WebSocket | null = null;
  protected _config: typeof WS_CONNECTION_CONFIG;
  protected _store: Store;
  protected _user = LStorage.getItem<JwtData>('PROFILE_META', true);

  constructor(store: Store) {
    super();
    this._config = WS_CONNECTION_CONFIG;
    this._store = store;
    this.on('authentication', ({ result }) => this.setAuth(result.success));
  }

  private get _urlWithParams() {
    if (!this._user) return this._config.url;
    const count = LStorage.getItem('OPEN_TABS') || '1';
    return `${this._config.url}?id=${this._user.id}&open_tabs=${count}`;
  }

  public connect = ({ callback }: WsConnectProps): void => {
    this._store.LoadingService.setLoading('WS_CONNECTION', true);
    this.connection = new WebSocket(this._urlWithParams);
    if (this.connection) {
      this.connection.onopen = () => this.handleOpen(callback);
      this.connection.onmessage = (e) => this._notify(e);
      this.connection.onerror = (e) => this._handleError(e as any);
      this.connection.onclose = () => this._handleClose();
    }
  };

  public handleOpen = (callback?: WsConnectProps['callback']): void => {
    if (callback && this.connection) {
      this.setConnectionState(true);
      this._store.LoadingService.setLoading('WS_CONNECTION', false);
      this._store.LoggerService.send.wsCrumb(`✅ OPEN ${this._config.url}`);
      callback(this.connection);
    }
  };

  public disconnect = (): void => {
    if (this.connection && this.isConnected) {
      this.connection.close();
      this.connection = null;
      this.setAuth(false);
      this.setConnectionState(false);
    }
  };

  public send<T>(data: WsRequest<T>, loadingKey: LoadingKey): void {
    this._store.LoadingService.setLoading(loadingKey, true);

    const message = JSON.stringify({
      id: this._user ? uniqueId(`${this._user.id}-`) : uniqueId(),
      ...data,
    });

    if (this.connection?.readyState === WebSocket.OPEN) {
      try {
        this.connection.send(message);
        this._store.LoggerService.send.wsCrumb(
          `🟧 REQUEST: [${loadingKey}]: ${data.method}`,
        );
      } catch (error: any) {
        console.log(error);
        this._store.LoadingService.setLoading(loadingKey, false);
        this._store.ErrorService.wsError('WS_CONNECTION', [
          { message: `❌ WS ERROR: ${error?.message || ''}` },
        ]);
      }
    } else {
      const message = 'Socket is not open';
      const { readyState } = this.connection || {};
      console.log(`❌ ${message}`, { readyState });
      this._store.ErrorService.wsError(loadingKey, [{ message }]);
    }
  }

  private _notify = (event: MessageEvent) => {
    try {
      const message = JSON.parse(event.data) as WsResponse<never>;
      const { method, result, error: errors } = message;
      const loadingKey = WS_RESPONSE_KEY_MAPPER[method];

      this._store.LoadingService.setLoading(loadingKey, false);
      this.emit(method, message);

      this._store.LoggerService.send.wsCrumb(
        `🟩 RESPONSE: [${loadingKey}]: ${method}`,
      );

      if (!result.success) {
        this._store.ModalService.modal.wsError(errors);
        this._store.ErrorService.wsError(loadingKey, errors, message);
      }
    } catch (err) {
      console.error(err);
      this._store.ErrorService.wsException('UNKNOWN_KEY', err, event);
    }
  };

  private _handleClose = () => {
    this.emit('ws_close');
    this.setAuth(false);
    this.setConnectionState(false);
    this._store.LoadingService.setLoading('WS_CONNECTION', false);
    if (this.connection && this.isConnected) {
      this._store.LoggerService.send.wsCrumb(
        `🔒 WS_CONNECTION_CLOSED ${this._config.url}`,
      );
    }
  };

  private _handleError = (event: Events.ErrorEvent) => {
    this.emit('ws_close');
    this.setAuth(false);
    this.setConnectionState(false);
    this._store.ErrorService.wsError(
      'WS_CONNECTION',
      [{ message: `${event?.message || 'Error'}` }],
      { event },
    );
  };

  public sendAuthentication() {
    const token = this._store.AuthService.accessToken;
    if (!token) {
      this._store.ErrorService.wsError('AUTH_WS_CONNECTION', [
        { message: 'Token not found!' },
      ]);
      return;
    }
    this.send(
      { method: 'authentication', params: { token } },
      'AUTH_WS_CONNECTION',
    );
  }
}
