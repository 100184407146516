import { PreloadLazy } from '@router/utils';

export const BIOMATERIALS_ROUTES: PrivateRoute = {
  group: 'BIOMATERIALS',
  name: 'biomaterials',
  path: '/biomaterials',
  exact: false,
  sidebar: true,
  icon: 'BloodtypeOutlined',
  activeIcon: 'BloodtypeBlackFilled',
  component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
  routes: [
    {
      name: 'biomaterials',
      path: '/biomaterials',
      exact: true,
      component: PreloadLazy(() => import('@views/Biomaterials')),
    },
    {
      name: 'biomaterials_planning',
      path: '/biomaterials/planing',
      exact: true,
      ws: true,
      component: PreloadLazy(() => import('@views/Biomaterials/Planning')),
      imagePath: () => import('@assets/img/preview/blood_test.svg'),
    },
    {
      name: 'biomaterials_taking',
      path: '/biomaterials/taking',
      ws: true,
      exact: true,
      component: PreloadLazy(() => import('@views/Biomaterials/Taking')),
      imagePath: () => import('@assets/img/preview/blood_test.svg'),
    },
    {
      group: 'BIOMATERIALS_MANAGE',
      name: 'biomaterials_manage',
      path: '/biomaterials/manage',
      exact: false,
      component: PreloadLazy(() => import('@router/components/LayoutRoutes')),
      imagePath: () => import('@assets/img/preview/schedule.svg'),
      routes: [
        {
          name: 'biomaterials_manage',
          path: '/biomaterials/manage',
          exact: true,
          component: PreloadLazy(() => import('@views/Biomaterials/Manage')),
        },
        {
          name: 'biomaterials_appointments',
          path: '/biomaterials/manage/appointments',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Manage/Appointments'),
          ),
          imagePath: () => import('@assets/img/preview/medical_research.svg'),
        },
        {
          name: 'biomaterials_manage_visits',
          path: '/biomaterials/manage/visits',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Manage/Visits'),
          ),
          ws: true,
          imagePath: () => import('@assets/img/preview/schedule.svg'),
        },
        {
          name: 'biomaterials_taken_successful',
          path: '/biomaterials/manage/successful',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Manage/TakenSuccessful'),
          ),
          imagePath: () => import('@assets/img/preview/biomaterial_types.svg'),
        },
        {
          name: 'biomaterials_taken_unsuccessful',
          path: '/biomaterials/manage/unsuccessful',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Manage/TakenUnsuccessful'),
          ),
          imagePath: () => import('@assets/img/preview/no-data.png'),
        },
        {
          group: 'BIOMATERIALS_SENT',
          name: 'biomaterials_sent_samples',
          path: '/biomaterials/manage/sent',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Biomaterials/Manage/Sent'),
          ),
          imagePath: () => import('@assets/img/preview/mail_sent.svg'),
        },
        {
          name: 'biomaterials_spoiled',
          path: '/biomaterials/manage/spoiled',
          exact: true,
          component: PreloadLazy(
            () => import('@views/Laboratory/Biomaterials/Spoiled'),
          ),
          imagePath: () => import('@assets/img/preview/blood_test.svg'),
        },
      ],
    },
  ],
};
